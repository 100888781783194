const routes = {
    "writing-calculators": {
        link: "/writing-calculators",
    },
    "writing-calculators/categories": {
        link: "/writing-calculators/:id",
    },
    "writing-calculators/metrics": {
        link: "/writing-calculators/:id/:category",
    },
    "writing-calculators/weightings": {
        link: "/writing-calculators/:id/:category/:year",
    },
};

export default routes;
