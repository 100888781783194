const routes = {
    devTasks: {
        link: "/devTasks",
    },
    "devTasks/new": {
        link: "/devTasks/new",
    },
    "devTasks/view": {
        link: "/devTasks/:id",
    },
};

export default routes;
