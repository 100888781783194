import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { css, SerializedStyles, Theme } from "@emotion/react";
import Tippy from "@tippyjs/react";
import Loading from "../utils/Loading";
import defaultTippyProps from "../../includes/tippy";

// Only add the odd colour to this.  Generally speaking we should be using "primary" and "secondary" to make
// Redesigns easier.
const getColorMap = (theme: Theme) => ({
    green: [
        theme.colours.green[700],
        theme.colours.green[800],
        theme.colours.white,
    ],
    primary: [
        theme.colours.blue[500],
        theme.colours.blue[600],
        theme.colours.white,
    ],
    red: [theme.colours.red[350], theme.colours.red[400], theme.colours.white],
    secondary: [
        theme.colours.blue[400],
        theme.colours.blue[700],
        theme.colours.white,
    ],
    yellow: [
        theme.colours.yellow,
        theme.colours.cream,
        theme.colours.blue[400],
    ],
    cancel: [
        theme.colours.status.error,
        theme.colours.status.error,
        theme.colours.white,
    ],
});

const getColor = (
    bordered: boolean,
    color: ButtonColor,
    theme: any,
    loading?: boolean,
) => {
    const [cssColor, hoverCssColor, textColour] = getColorMap(theme)[color];

    if (bordered) {
        return css`
            background: transparent;
            border: 1px solid ${cssColor};
            color: ${cssColor};

            ${!loading &&
            css`
                &:hover {
                    background: ${cssColor};
                    color: ${textColour};
                }
            `}
        `;
    }
    return css`
        background: ${cssColor};
        border: 0;
        color: ${textColour};

        ${!loading &&
        css`
            &:hover {
                background: ${hoverCssColor};
                color: ${textColour};
            }
        `}
    `;
};

const sizes = {
    sm: "80px",
    md: "110px",
    lg: "124px",
};

export type ButtonColor =
    | "green"
    | "primary"
    | "red"
    | "secondary"
    | "yellow"
    | "cancel";

type Props = {
    bordered?: boolean;
    forceLoading?: boolean;
    disabled?: boolean;
    disabledTooltipMessage?: string;
    children?: React.ReactNode;
    color?: ButtonColor;
    extraStyles?: SerializedStyles;
    onClick?: (e: React.MouseEvent) => void;
    type?: "button" | "submit" | "reset" | undefined;
    size?: "sm" | "md" | "lg";
    className?: string;
    href?: string;
    tabIndex?: number;
};

const Button: React.FC<Props> = ({
    bordered,
    children,
    color,
    extraStyles,
    onClick,
    type,
    forceLoading,
    disabled,
    disabledTooltipMessage,
    size = "lg",
    className,
    href,
    tabIndex = 0,
}) => {
    const [loading, setLoading] = useState(false);

    const styles: any = useMemo(
        () => (theme: Theme) => css`
            position: relative;
            border-radius: 3px;
            font-size: ${theme.fonts.baseSize};
            font-family: ${theme.fonts.frutiger};
            font-weight: ${theme.fonts.weights.light};
            outline: none;
            padding: 5px 10px;
            white-space: nowrap;
            min-width: ${sizes[size]};
            height: 32px;
            transition: background-color 300ms ease-in-out;
            ${!(disabled || loading || forceLoading) &&
            css`
                cursor: pointer;
            `};
            ${getColor(
                bordered || false,
                color || "primary",
                theme,
                loading || forceLoading,
            )};
            opacity: ${disabled || loading || forceLoading ? "0.5" : "1"};

            ${href &&
            css`
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            `}

            ${extraStyles}
        `,
        [
            bordered,
            color,
            extraStyles,
            loading,
            forceLoading,
            disabled,
            size,
            href,
        ],
    );

    const handleClick = useCallback(
        (e: any) => {
            if (onClick) {
                e.preventDefault();
                const clickResult: any = onClick(e);
                if (clickResult instanceof Promise) {
                    setLoading(true);
                    clickResult.finally(() => {
                        setLoading(false);
                    });
                }
            }
        },
        [onClick],
    );

    const isDisabled = disabled || loading || forceLoading;
    const isLoading = loading || forceLoading;
    const loadingNode = useMemo(
        () => <Loading small overlay={false} ringIndicator />,
        [],
    );

    const renderButton = useCallback(
        () => (
            <button
                css={styles}
                className={className}
                type={type}
                onClick={handleClick}
                disabled={isDisabled}
                tabIndex={tabIndex}
            >
                {isLoading && loadingNode}
                {children}
            </button>
        ),
        [
            styles,
            className,
            type,
            handleClick,
            children,
            isDisabled,
            isLoading,
            loadingNode,
            tabIndex,
        ],
    );

    const renderLink = useCallback(() => {
        const route = href || "#";
        return (
            <div css={linkWrapperStyle(isDisabled)}>
                <Link
                    css={styles}
                    className={className}
                    to={isDisabled ? "#" : route}
                    onClick={onClick}
                    tabIndex={tabIndex}
                >
                    {isLoading && loadingNode}
                    {children}
                </Link>
            </div>
        );
    }, [
        styles,
        className,
        children,
        href,
        onClick,
        isDisabled,
        isLoading,
        loadingNode,
        tabIndex,
    ]);

    return disabled && disabledTooltipMessage ? (
        <Tippy
            content={disabledTooltipMessage}
            hideOnClick={false}
            {...defaultTippyProps}
        >
            <span>{href ? renderLink() : renderButton()}</span>
        </Tippy>
    ) : href ? (
        renderLink()
    ) : (
        renderButton()
    );
};

Button.defaultProps = {
    bordered: false,
    color: "primary",
    type: "button",
    forceLoading: false,
    disabled: false,
};

const linkWrapperStyle = (disabled?: boolean) => css`
    ${disabled &&
    css`
        pointer-events: none;
    `}
`;

export default memo(Button);
