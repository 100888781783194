import { useCallback, useEffect, useRef, useState } from "react";
import { persist, retrieve } from "../includes/localStorage";

type NewState = {
    [key: string]: any;
};

type NewStateFunction = <T>(prevState: T) => {};

export type UpdateFunction = (
    newState: NewState | NewStateFunction,
    merge?: boolean,
) => NewState;

/**
 * Manage state similar to setState on class components.
 *
 * @param {object} defaultState
 * @param {string|null} persistKey
 *
 * @returns {[unknown, (...args: any[]) => any]}
 */
const useSetState = <T>(
    defaultState: T,
    persistKey: string | null = null,
): [T, UpdateFunction] => {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    // if state is persisted, check for stored state, if found we merge with default state in case default state
    // has changed since state was stored
    if (persistKey) {
        const storedState = retrieve(persistKey);

        if (storedState) {
            defaultState = { ...defaultState, ...storedState };
        }
    }

    const defaultStateRef = useRef<T>(defaultState);
    const [state, setState] = useState<T>(defaultState);

    const updateState = useCallback(
        (newState: NewState | NewStateFunction, merge: boolean = true) => {
            if (!mounted.current) {
                return;
            }

            setState((prevState: T) => {
                let returnState = prevState;

                // if we have a function, pass previous state
                const updatedState =
                    typeof newState === "function"
                        ? newState(prevState)
                        : newState;

                // merge new state with previous state
                if (merge) {
                    returnState = {
                        ...prevState,
                        ...updatedState,
                    };

                    // if merge is disabled, merge with the default state
                } else {
                    returnState = {
                        ...defaultStateRef.current,
                        ...updatedState,
                    };
                }

                if (persistKey) {
                    persist(persistKey, returnState);
                }

                return returnState;
            });
        },
        [persistKey],
    ) as UpdateFunction;

    return [state, updateState];
};

export default useSetState;
