// For an explanation on policy details, see:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
// and
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-errorPolicy
import {
    ApolloClient,
    ApolloLink,
    ApolloQueryResult,
    FetchResult,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import * as authService from "./auth";
import { API_BASE_URL } from "../../config";

const defaultOptions: any = {
    watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
    },
    query: {
        fetchPolicy: "network-only",
        errorPolicy: "none",
    },
};

// Global error handle for when a user is logged out.  Redirects to login page.
const errorHandlerLink: any = onError((errorHandler: any) => {
    const { networkError } = errorHandler;

    if (networkError && networkError.statusCode === 401) {
        const event = new Event("authError");
        window.dispatchEvent(event);

        // capture response information for network errors
    } else if (networkError && networkError.response) {
        let body = networkError.bodyText || "";

        // limit length, max size of sentry logging request is 100kB
        if (body.length >= 5000) {
            body = body.substring(0, 5000);
        }
    }
});

/**
 * Adds bearer token to apollo requests.
 *
 * @type {ApolloLink}
 */
const authMiddleware: any = setContext(async (operation: any) => {
    if (!authService.hasTokenExpired()) {
        // refresh token if it needs refreshing
        if (authService.shouldTokenRefresh()) {
            try {
                await authService.refreshToken();
            } catch (e: any) {
                // do something
            }
        }

        // add the authorization to the headers
        return {
            credentials: "include",
        };
    }

    return {
        credentials: "include",
    };
    // if no token we include credentials so session can be used, if there is a valid session a token
    // will be returned in the headers
    // return withCredentials;
});

const authAfterware: any = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
        const {
            response: { headers },
        } = operation.getContext();
        if (headers) {
            authService.updateTokenFromHeaders(headers);
        }

        return response;
    }),
);

const createClient: any = () =>
    new ApolloClient({
        // By default, this client will send queries to the
        //  `/graphql` endpoint on the same host
        // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
        // to a different host
        link: ApolloLink.from([
            authMiddleware,
            authAfterware,
            errorHandlerLink,
            new HttpLink({ uri: `${API_BASE_URL}/graphql` }),
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                AdminTeacher: {
                    fields: {
                        groups: {
                            merge(existing, incoming) {
                                return incoming;
                            },
                        },
                    },
                },
            },
        }),
        defaultOptions,
    });

export default createClient();

type Result = ApolloQueryResult<any> | FetchResult;
/**
 * Convenience method for getting gql response data.
 *
 * @param response
 * @returns {*}
 */
export const getResponseData = (response: Result) => {
    const data = response?.data ?? response;
    const responses = Object.keys(data);
    return responses.length === 1 ? data[responses[0]] : data;
};
