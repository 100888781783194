import colours from "./colours";
import sizes from "./sizes";

const lists = {
    cellSpacing: `${sizes.spacing.md} ${sizes.spacing.md}`,
    rowBackgroundColour: colours.grey[100],
    rowBackgroundColourAlt: colours.grey[200],
};

export default lists;
