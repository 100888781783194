const routes = {
    reports: {
        link: "/reports",
    },
    "reports/errors": {
        link: "/reports/:id/errors",
    },
    "reports/view": {
        link: "/reports/:id",
    },
};

export default routes;
