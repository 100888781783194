import { TippyProps } from "@tippyjs/react";
import theme from "../../theme";

const arrowSvgString = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -14 20 14" style="width: 20px;">
        <path d="M-10 0 0-14 10 0"/>
    </svg>
`;

const defaultTippyProps: Partial<TippyProps> = {
    placement: "bottom",
    className: "tippy-white-theme",
    animation: "scale-subtle",
    inertia: true,
    arrow: arrowSvgString,
    zIndex: theme.zIndex.zIndexMax,
};

export default defaultTippyProps;
