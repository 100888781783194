export const METRIC_LABELS = {
    SSS: "Simple",
    SSComp: "Simple comp.",
    SSA: "Adverbial",
    SSP: "Prepositional",
    SSW: "W-Start",
    SSExp: "Explore",
    SSV: "Very short",
    SSEm: "Em-dash",
    SSIng: "Ing start",
    SSed: "Ed start",
    SSRWB: "RWB",
    SSSC: "Semi-colon",
    SSQ: "Question",
    SSO: "Other",
    SSPow: "Power",
    SSCon: "Conjunction start",
    SSColon: "Colon",
    SSCast: "Cast and flick",
    SSRK: "Royal knockout",
    SSI: "Incomplete",
    SSIR: "Incomplete runon",

    AvgPW: "Words/para",
    AvgPS: "Sent/para",
    AvgPSS: "Styles/para",
    AvgS: "Words/sent",
    FKGL: "FK grade level",
    FRE: "Flesch RE",
    NoPronoun: "Pronoun sentences",
    NoProper: "Proper noun sentences",
    TotalProper: "Proper nouns",
    TotalW: "Words",
    WAGFI: "GF Index",
    WAGrammarRate: "Grammar error rate",
    WAGrammarDens: "Grammar error density",
    WAPrecRate: "Precision rate",
    WASVarRate: "Sent. variation",
    Structure: "Sent. Structure Quality",

    // paragraph
    AvgSP: "Words/sent",
    LongS: "Longest sent",
    NoCap: "Capitalisation errors",
    NoS: "Sentences",
    NoSS: "Styles",
    PACWCount: "Complex words",
    PAGFI: "GF Index",
    PAGrammarRate: "Grammar error rate",
    PAGrammarDens: "Grammar error density",
    PANoPrecS: "Precision sentences",
    PANoSpellS: "Spelling error sentences",
    PAOrder: "Paragraph number",
    PAPrec: "Precision",
    PAPrecDens: "Precision density",
    PASpellDens: "Spelling error density",
    PASpellRate: "Spelling error rate",
    PASSI: "Incomplete sentences",
    PASSIR: "Incomplete runons",
    PASSS: "Simple sentences",
    PAUSS: "Unique styles",
    PASVarRate: "Sent. length variation",
    PAW: "Words",
    ShortS: "Shortest sent.",
    Syllables: "Syllables",
    TotalCap: "Capitals",
    TotalSpell: "Spelling errors",
    WANoSSUsed: "Sentence styles used",

    // sentence
    PunctuationErrors: "Punctuation error list",
    SACap: "Capitalised",
    SACWCount: "Complex words",
    SAGrammar: "Grammar errors",
    SAOrder: "Sentence number",
    SAPrec: "Precision",
    SAPrecision: "Precision list",
    SAPronoun: "Pronouns",
    SAProper: "Proper nouns",
    SAPunc: "Punctuation errors",
    SASS: "Style",
    SASpell: "Spelling errors",
    SAText: "Text",
    SAW: "Words",
    SEPunc: "Punctuation sent.",
    SpellingErrors: "Spelling errors list",
    ProperNouns: "Proper noun list",
    GrammarErrors: "Grammar errors list",
};

export default METRIC_LABELS;
