import rootRoutes from "../../pages/routes";
import importRoutes from "../../pages/imports/routes";
import schoolRoutes from "../../pages/schools/routes";
import userRoutes from "../../pages/users/routes";
import reportRoutes from "../../pages/reports/routes";
import calculatorRoutes from "../../pages/calculators/routes";
import writingCalculatorRoutes from "../../pages/writing-calculators/routes";
import schoolReportsRoutes from "../../pages/schoolReports/routes";
import devTasksRoutes from "../../pages/devTasks/routes";
import grantAccessRoutes from "../../pages/grantAccess/routes";

const routes: any = {
    ...rootRoutes,
    ...importRoutes,
    ...schoolRoutes,
    ...reportRoutes,
    ...calculatorRoutes,
    ...writingCalculatorRoutes,
    ...schoolReportsRoutes,
    ...userRoutes,
    ...devTasksRoutes,
    ...grantAccessRoutes,
};

/**
 * Get a route by its name.
 *
 * @param {string} name
 * @return string
 */
export const route = (name: string): string => {
    const config = routes[name] ?? null;
    let r = "";

    if (!config) {
        throw new Error(`Unable to find route config for ${name}`);
    }

    const getRouteFromConfig = (c: FieldList) => c?.route ?? c?.link;

    r = getRouteFromConfig(config);

    // if a child route, we remove the parent route
    if (name.indexOf("/") > 0) {
        const parentName = name.substring(0, name.lastIndexOf("/"));
        const parentConfig = routes[parentName];

        if (!parentConfig) {
            throw new Error(
                `Could not find parent route ${parentName} for route ${name}`,
            );
        }

        r = r.replace(getRouteFromConfig(parentConfig), "");

        // remove leading slash
        if (r.indexOf("/") === 0) {
            r = r.substring(1);
        }
    }

    return r;
};

export type Params = {
    [key: string]: string | undefined;
};

export const link = (
    name: string,
    params: Params = {},
    queryParams: Params = {},
): string => {
    let link = Object.prototype.hasOwnProperty.call(routes, name)
        ? routes[name].link
        : "/";

    // replace params
    Object.keys(params).forEach((key: string) => {
        link = link.replace(`:${key}`, params[key]);
    });

    // append query params to link
    if (queryParams) {
        link = appendQueryString(link, queryParams);
    }

    return link;
};

/**
 * Appends the params as a querystring on the url.
 *
 * @param url
 * @param params
 * @return {string}
 */
export function appendQueryString(url: string, params: Params = {}): string {
    const query = Object.keys(params).reduce((str: string, param: string) => {
        const val = encodeURIComponent(params?.[param] ?? "");

        return str ? `${str}&${param}=${val}` : `${param}=${val}`;
    }, "");

    return query ? `${url}?${query}` : url;
}

export default routes;
