import React, { useEffect, useMemo, useState } from "react";
import { css, keyframes, Theme } from "@emotion/react";
import { sideMenuWidth } from "includes/sideMenu";

type Props = {
    overlay?: boolean;
    ringIndicator?: boolean;
    small?: boolean;
    onTop?: boolean;
    fitToScreen?: boolean;
    delay?: number;
};

const Loading: React.FC<Props> = ({
    ringIndicator,
    onTop,
    overlay,
    fitToScreen,
    small,
    delay = 300,
}) => {
    const [showLoading, setShowLoading] = useState(!delay);

    const left = !overlay ? 0 : sideMenuWidth(undefined);

    // show loading after delay if set
    useEffect(() => {
        if (delay) {
            setTimeout(() => setShowLoading(true), delay);
        }
    }, [delay]);

    const loadingStyle: any = useMemo(() => {
        if (onTop) {
            return css`
                width: 100%;
                height: 50px;
                position: relative;
            `;
        }
        return (theme: any) => css`
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            display: flex;
            z-index: ${theme.zIndex.zIndexHigh - 2};
        `;
    }, [onTop]);

    const loadingRingStyle: any = useMemo(
        () => css`
            display: inline-block;
            position: relative;
            width: ${small ? 30 : 64}px;
            height: ${small ? 30 : 64}px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        `,
        [small],
    );

    const overlayStyle: any = useMemo(() => {
        if (fitToScreen) {
            return (theme: any) => css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.25;
                z-index: ${theme.zIndex.zIndexHigh - 1};
            `;
        }

        return (theme: any) => css`
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.25;
            z-index: ${theme.zIndex.zIndexHigh - 1};
        `;
    }, [fitToScreen]);

    const loadingContentStyle: any = useMemo(
        () => (theme: any) => css`
            position: ${fitToScreen ? "fixed" : "absolute"};
            top: 50%;
            left: calc(50% + (${left}px / 2));
            transform: translate(-50%, -50%);
            z-index: ${theme.zIndex.zIndexHigh}; // Ensure visibility
        `,
        [fitToScreen, left],
    );

    return !showLoading ? null : (
        <div css={loadingStyle}>
            {overlay && <div css={overlayStyle} />}
            {ringIndicator ? (
                <div css={loadingRingStyle}>
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                    <div css={loadingRingSectionStyle(small)} />
                </div>
            ) : (
                <div css={loadingContentStyle}>
                    <div css={loadingDotStyle(1)}>w</div>
                    <div css={loadingDotStyle(2)}>t</div>
                    <div css={loadingDotStyle(3)}>b</div>
                </div>
            )}
        </div>
    );
};

Loading.defaultProps = {
    overlay: false,
    ringIndicator: false,
    small: false,
    onTop: false,
    fitToScreen: false,
};

const ringLoadingKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const bloopLoadingKeyframe = keyframes`
    50% {
        transform: scale(1.3) translateY(-5px);
        filter: brightness(1);
    }
`;

const loadingRingSectionStyle: any = (small?: boolean) => (theme: any) => css`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 9%;
    border: ${small ? 4 : 6}px solid ${theme.colours.white};
    border-radius: 50%;
    animation: ${ringLoadingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.colours.white} transparent transparent transparent;

    :nth-of-type(N + 1) {
        animation-delay: -0.45s;
    }
    :nth-of-type(N + 2) {
        animation-delay: -0.3s;
    }
    :nth-of-type(N + 3) {
        animation-delay: -0.15s;
    }
`;

const dot1: any = (theme: Theme) => css`
    background-color: ${theme.colours.blue[500]};
`;

const dot2: any = (theme: Theme) => css`
    background-color: ${theme.colours.yellow};
    animation-delay: 0.15s;
    margin-left: 9px;
`;

const dot3: any = (theme: Theme) => css`
    background-color: ${theme.colours.blue[500]};
    animation-delay: 0.3s;
    margin-left: 9px;
`;

const loadingDotStyle: any = (dotIndex: number) => (theme: any) => {
    const dotStyle = ((theme) => {
        switch (dotIndex) {
            case 1:
                return dot1(theme);
            case 2:
                return dot2(theme);
            case 3:
                return dot3(theme);
            default:
                return "";
        }
    })(theme);

    return css`
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 12px;
        line-height: 16px;
        color: ${theme.colours.white};
        display: inline-block;
        text-align: center;
        font-family: ${theme.fonts.frutiger};
        animation: 1.5s ${bloopLoadingKeyframe} ease-in-out infinite;

        ${dotStyle};
    `;
};

export default Loading;
