import borderAndShadow from "./borderAndShadow";
import breakpoints from "./breakpoints";
import colours from "./colours";
import fonts, { fontImports } from "./fonts";
import forms from "./forms";
import keyframes from "./keyframes";
import lists from "./lists";
import mixins from "./mixins";
import popover from "./popover";
import root from "./root";
import sizes from "./sizes";
import tippy from "./tippy";
import transform from "./transform";
import transitions from "./transitions";
import zIndex from "./zIndex";
import graphs from "./graphs";

const theme = {
    borderAndShadow,
    breakpoints,
    colours,
    fonts,
    fontImports,
    forms,
    keyframes,
    lists,
    mixins,
    popover,
    root,
    sizes,
    tippy,
    transform,
    transitions,
    zIndex,
    graphs,
};

export type Theme = typeof theme;

export default theme;
