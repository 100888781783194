const routes = {
    users: {
        link: "/users",
    },
    "users/edit": {
        link: "/users/:id/edit",
    },
    "users/view": {
        link: "/users/:id",
    },
    "users/classes": {
        link: "/users/:id/classes",
    },
    "users/class-folders": {
        link: "/users/:id/class-folders",
    },
    "users/year-folders": {
        link: "/users/:id/year-folders",
    },
    "users/essays": {
        link: "/users/:id/essays",
    },
    "users/assignments": {
        link: "/users/:id/assignments",
    },
};

export default routes;
