import { css } from "@emotion/react";
import emotionReset from "emotion-reset";
import fonts, { fontImports } from "./fonts";
import tippy from "./tippy";
import graphs from "./graphs";

const root = css`
    ${emotionReset}
    ${fontImports}
    
    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        font-size: ${fonts.baseSize};
        line-height: ${fonts.lineHeight};
        font-family: ${fonts.primary};
        font-weight: ${fonts.weights.light};
    }

    * {
        box-sizing: border-box;
    }

    .dropdown__toggle-menu--open {
        .visx-tooltip {
            display: none;
        }
    }

    ${graphs.modal}
    ${tippy.whiteTheme}
`;

export default root;
