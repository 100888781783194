const routes = {
    calculators: {
        link: "/calculators",
    },
    "calculators/errors": {
        link: "/calculators/:id/errors",
    },
    "calculators/view": {
        link: "/calculators/:id",
    },
    "calculators/new": {
        link: "/calculators/new",
    },
    "calculators/edit": {
        link: "/calculators/:id/edit",
    },
};

export default routes;
