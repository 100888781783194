import { css } from "@emotion/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale-subtle.css";
import colours from "./colours";
import borderAndShadow from "./borderAndShadow";
import fonts from "./fonts";

const tippy = {
    whiteTheme: css`
        .tippy-white-theme {
            background: ${colours.white};
            box-shadow: ${borderAndShadow.boxShadow};
            color: ${colours.blue[500]};
            font-weight: ${fonts.weights.light};

            &[data-placement^="bottom"] .tippy-arrow {
                border-bottom-color: ${colours.white};
            }

            &[data-placement^="top"] .tippy-arrow {
                border-top-color: ${colours.white};
            }
        }

        .tippy-box {
            filter: drop-shadow(0px 1px 3px rgb(0 0 0 / 30%));
            // margin added as a work around to Tippy's "distance" prop;
            // since it is not currently working properly
            margin: 7px;
            font-weight: ${fonts.weights.light};

            .tippy-svg-arrow {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &[data-placement^="top"] {
                .tippy-svg-arrow {
                    bottom: -15px;

                    & > svg {
                        transform: rotate(180deg);
                    }
                }
            }

            &[data-placement^="right"] {
                .tippy-svg-arrow {
                    left: -15px;

                    & > svg {
                        transform: rotate(-90deg) scaleX(0.8);
                    }
                }
            }

            &[data-placement^="bottom"] {
                .tippy-svg-arrow {
                    top: -15px;
                }
            }

            &[data-placement^="left"] {
                .tippy-svg-arrow {
                    right: -15px;

                    & > svg {
                        transform: rotate(90deg) scaleX(0.8);
                    }
                }
            }
        }

        .tippy-white-theme {
            background: ${colours.white};
            color: ${colours.poloBlue2};

            .tippy-svg-arrow > svg {
                fill: ${colours.white};
            }
        }

        .tippy-grey-theme {
            background: ${colours.gallery};
            color: ${colours.black};

            .tippy-svg-arrow > svg {
                fill: ${colours.gallery};
            }
        }

        .tippy-button-theme {
            background: ${colours.shipCove};
            color: ${colours.white};

            .tippy-svg-arrow > svg {
                fill: ${colours.shipCove};
            }
        }

        [data-tippy-root] {
            max-width: calc(100% - 10px);
        }

        .tippy-content {
            word-break: break-word;
        }

        .overflow-wrap-anywhere {
            overflow-wrap: anywhere;
        }
    `,
};

export default tippy;
