import React, { useContext, useMemo } from "react";
import useSetState from "../hooks/useSetState";

type State = {
    user: any;
};

const AppContext = React.createContext<any>(null);

export const useAppState = () => {
    const [state, setState] = useContext(AppContext);
    return [state, setState];
};

type Props = {
    children: React.ReactNode;
    initialState: State;
};

export const AppState: React.FC<Props> = ({ children, initialState }) => {
    const [state, setState] = useSetState<State>(initialState);

    // only change value when state has changed
    const value = useMemo(() => [state, setState], [state, setState]);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContext;

export const useUser = () => useContext(AppContext);
