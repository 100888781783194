export const SITE_NAME_BASE = "Writer's Toolbox";
export const SITE_NAME = "Headquarters";
export const SITE_NAME_SHORT = "HQ";
export const SITE_NAME_FULL = `${SITE_NAME_BASE} ${SITE_NAME}`;

/**
 * Separator for site title and the page name.
 *
 * @var {string}
 */
export const SITE_TITLE_SEPARATOR = " - ";

export const getSiteTitle = (pageTitle: string) =>
    pageTitle ? pageTitle + SITE_TITLE_SEPARATOR + SITE_NAME : SITE_NAME;
