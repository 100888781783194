const routes = {
    grantAccess: {
        link: "/grantAccess",
    },
    "grantAccess/new": {
        link: "/grantAccess/new",
    },
    "grantAccess/edit": {
        link: "/grantAccess/:id/edit",
    },
    "grantAccess/view": {
        link: "/grantAccess/:id",
    },
};

export default routes;
