import { retrieveObject } from "includes/localStorage";

const MENU_EXPANDED_WIDTH = 250;
const MENU_COLLAPSED_WIDTH = 62;

export const defaultSideMenuExpanded = () =>
    retrieveObject("SideMenu")?.expanded === undefined
        ? true
        : retrieveObject("SideMenu").expanded;

export const sideMenuWidth = (expanded: boolean | undefined): number => {
    if (expanded) {
        return expanded ? MENU_EXPANDED_WIDTH : MENU_COLLAPSED_WIDTH;
    }
    return defaultSideMenuExpanded()
        ? MENU_EXPANDED_WIDTH
        : MENU_COLLAPSED_WIDTH;
};

const v = {
    defaultSideMenuExpanded,
    sideMenuWidth,
};

export default v;
