const sizes = {
    padding: "20px",
    smallRadius: "3px",
    smallRadiusBottom: "0 0 3px 3px",
    smallRadiusTop: "3px 3px 0 0",
    topHeaderHeight: "56px",
    pageBodyMinHeight: "770px",
    pageBodyMarginBottom: "30px",
    spacing: {
        sm: "8px",
        md: "16px",
        lg: "32px",
    },
};

export default sizes;
