const routes = {
    schools: {
        link: "/schools",
    },
    "schools/edit": {
        link: "/schools/:id/edit",
    },
    "schools/new": {
        link: "/schools/new",
    },
    "schools/admins": {
        link: "/schools/:schoolId/admins",
    },
    "schools/admins/view": {
        link: "/schools/:schoolId/admins/:id",
    },
    "schools/admins/edit": {
        link: "/schools/:schoolId/admins/:id/edit",
    },
    "schools/students": {
        link: "/schools/:schoolId/students",
    },
    "schools/students/new": {
        link: "/schools/:schoolId/students/new",
    },
    "schools/students/view": {
        link: "/schools/:schoolId/students/:id",
    },
    "schools/students/classes": {
        link: "/schools/:schoolId/students/:id/classes",
    },
    "schools/students/class-folders": {
        link: "/schools/:schoolId/students/:id/class-folders",
    },
    "schools/students/year-folders": {
        link: "/schools/:schoolId/students/:id/year-folders",
    },
    "schools/students/essays": {
        link: "/schools/:schoolId/students/:id/essays",
    },
    "schools/students/edit": {
        link: "/schools/:schoolId/students/:id/edit",
    },
    "schools/teachers": {
        link: "/schools/:schoolId/teachers",
    },
    "schools/teachers/new": {
        link: "/schools/:schoolId/teachers/new",
    },
    "schools/teachers/view": {
        link: "/schools/:schoolId/teachers/:id",
    },
    "schools/teachers/classes": {
        link: "/schools/:schoolId/teachers/:id/classes",
    },
    "schools/teachers/class-folders": {
        link: "/schools/:schoolId/teachers/:id/class-folders",
    },
    "schools/teachers/essays": {
        link: "/schools/:schoolId/teachers/:id/essays",
    },
    "schools/teachers/assignments": {
        link: "/schools/:schoolId/teachers/:id/assignments",
    },
    "schools/teachers/edit": {
        link: "/schools/:schoolId/teachers/:id/edit",
    },
    "schools/student-import": {
        link: "/schools/:id/student-import",
    },
    "schools/teacher-import": {
        link: "/schools/:id/teacher-import",
    },
    "schools/view": {
        link: "/schools/:id",
    },
    "schools/classes": {
        link: "/schools/:schoolId/classes",
    },
    "schools/classes/view": {
        link: "/schools/:schoolId/classes/:id",
    },
    "schools/classes/students": {
        link: "/schools/:schoolId/classes/:id/students",
    },
    "schools/classes/new": {
        link: "/schools/:schoolId/classes/new",
    },
    "schools/classes/edit": {
        link: "/schools/:schoolId/classes/:id/edit",
    },
    "schools/school-usage": {
        link: "/schools/:schoolId/school-usage/",
    },
    "schools/sentence-graph": {
        link: "/schools/:schoolId/sentence-graph/",
    },
    "schools/analysis-graph": {
        link: "/schools/:schoolId/analysis-graph/",
    },
    "schools/rollovers": {
        link: "/schools/:schoolId/rollovers",
    },
    "schools/rollovers/view": {
        link: "/schools/:schoolId/rollovers/:id",
    },
    "schools/imports": {
        link: "/schools/:schoolId/imports",
    },
    "schools/imports/view": {
        link: "/schools/:schoolId/imports/:id",
    },
};

export default routes;
