import { keyframes } from "@emotion/react";
import transform from "./transform";

const bloop = keyframes`
    0% {
        transform: none;
    }

    5% {
        transform: ${transform.bloopScale};
    }

    10% {
        transform: none;
    }
`;

const slowBloop = keyframes`
    0% {
        transform: none;
    }

    2.5% {
        transform: ${transform.bloopScale};
    }

    5% {
        transform: none;
    }
`;

const videoSnap = keyframes`
    5% {
        transform: rotate(-3deg);
    }

    10% {
        transform: rotate(0deg);
    }
`;

const wcfDraw = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;

const wcfTextShow = keyframes`
    to {
        opacity: 1;
    }
`;

const v = {
    bloop,
    slowBloop,
    videoSnap,
    wcfDraw,
    wcfTextShow,
};

export default v;
