import { css } from "@emotion/react";
import search from "images/search.svg";
import colours from "./colours";
import breakpoints from "./breakpoints";
import transform from "./transform";
import transitions from "./transitions";
import mixins from "./mixins";
import borderAndShadow from "./borderAndShadow";
import zIndex from "./zIndex";
import sizes from "./sizes";

const graphs = {
    metrics: css`
        .metrics {
            position: relative;
            height: auto;
            margin: 0;

            ${breakpoints.only("5xl")} {
                margin-top: 22px;
                height: 226px;
            }
            ${breakpoints.only("4xl")} {
                margin-top: 26px;
                height: 208px;

            }
            ${breakpoints.only("3xl")} {
                margin-top: 30px;
                height: 187px;
            }

            &__bullseye {
                position: absolute;
                left: 46px;
                top: 28px;
                width: 66px;
                height: 66px;
            }

            &__graph {
                text-align: center;
                position: relative;
                height: 110px;
            }

            &__graph--full {
                width: auto;
                height: 135px;

                svg {
                    height: 135px;
                    width: 135px;
                }
            }

            &__graph--vertical {
                height: 128px;
            }

            &__graph-pointer {
                width: 64px;
                height: 10px;
                position: relative;
                left: -22px;
                bottom: 15px;
                transition: transform 1.3s ease-in-out;
                transform-origin: 55px 5px;
            }

            &__graph-half-circle {
                display: block;
                width: 100%;
            }

            &__half-circle-svg,
            &__half-circle-thick-svg {
                width: 140px;
                height: 80px;

                ${breakpoints.only("5xl")} {
                    width: 140px * 1.26;
                    height: 80px * 1.26;
                }

                ${breakpoints.only("4xl")} {
                    width: 140px * 1.18;
                    height: 80px * 1.18;
                }

                ${breakpoints.only("3xl")} {
                    width: 140px * 1.1;
                    height: 80px * 1.1;
                }
            }

            &__vertical-graph {
                position: absolute;
                transform: rotate(90deg);

                width: 117px;
                height: 63px;
                left: 40px;
                top: 36px;
            }

            &__range {
                display: flex;
                justify-content: space-between;
                position: relative;
                top: -20px;
                padding: 0 6px;
            }

            &__range-values {
                width: 32px;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: ${colours.naturalGrey};
            }

            &__range-subtext {
                font-size: 12px;
                color: ${colours.naturalGrey};
                line-height: 15px;
            }

            &__value {
                margin: 0;
                line-height: 1.1;
                color: ${colours.silverChalice};
                font-size: 24px;
                text-align: center;
                margin-bottom: 5px;
            }

            &__value--high {
                font-size: 20px;
            }

            &__value--bold {
                font-weight: 400;
            }

            &__text {
                margin: 0 0 5px;
                line-height: 1.1;
                font-size: 12px;
                text-align: center;
                color: ${colours.tundora};
            }

            &__text--bottom {
                margin-top: 37px;
            }

            &__summary--center {
                top: 41%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                text-align: center;
            }

            &__summary--bottom {
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
            }

            &__summary--bottom-single-line {
                bottom: 13px;
            }

            &__big-number {
                font-size: 24px;
                text-align: center;
                line-height: 35px;
                height: 35px;
                font-weight: 400;
                margin-left: 10px;
            }

            &__graph-number {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-left: 0;
                line-height: 60px;
            }

            &__percentage {
                vertical-align: super;
                font-size: 12px;
            }

            &__text-red {
                color: ${colours.crimson};
            }

            &__text-orange {
                color: ${colours.sun};
            }

            &__text-light-green {
                color: ${colours.sushi};
            }

            &__text-green {
                color: ${colours.chateauGreen};
            }

            &__round {
                text-align: center;
            }

            &__round-value {
                border-radius: 50%;
                color: ${colours.white};
                background: ${colours.danube};
                margin: 0 auto;
                animation: 0% {
                    transform: none;
                } 2.5% {
                    transform: ${transform.bloopScale};
                } 5% {
                    transform: none;
                } 8s infinite;
                animation-delay: 4.4s;
                width: 90px;
                height: 90px;
                line-height: 90px;
                font-size: 30px;
            }

            &__round--blue & {
                background: ${colours.denim};
            }

            &__round--yellow & {
                background: ${colours.lightningYellow};
            }

            &__round--orange & {
                background: ${colours.sun};
            }

            &__round--green & {
                background: ${colours.chateauGreen};
            }
        }
    `,
    dropdown: css`
        .dropdown {
            height: 32px;
            position: relative;

            .dropdown:not(.dropdown--no-scale-up) {
                ${breakpoints.scalePropUp("height", "32px")};
            }

            &:focus {
                outline: 1px solid ${colours.alto2};
            }

            &__search {
                background: url(${search}) right center no-repeat;
                margin: 0 auto 1px;
                width: 100%;
                padding-right: 24px;
                height: 28px;
                line-height: 28px;
                border: 0;
                text-align: left;
                font-weight: 300;
                display: block;
                border-top-left-radius: ${borderAndShadow.smallRadius};
                border-top-right-radius: ${borderAndShadow.smallRadius};

                &:focus {
                    outline: none;
                    background-color: ${colours.white};
                }
            }

            &__clear {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                height: 32px;
                padding: 0 5px;
                top: 0;
                right: 24px;

                svg {
                    height: 8px;
                    width: auto;

                    path {
                        fill: ${colours.boulder};
                    }
                }
            }

            &__menu {
                min-width: 100%;
                display: none;
                background-color: ${colours.white};
                position: absolute;
                left: 0;
                z-index: ${zIndex.zIndexMediumHigh};
                max-height: 240px;
                overflow: auto;
                border-radius: ${borderAndShadow.smallRadius};
                box-shadow: ${borderAndShadow.boxShadow};
            }

            &__menu--show {
                display: block;
            }

            &__toggle-menu {
                font-size: 16px;
                height: 32px;
                line-height: 32px;
                font-weight: 300;
                color: ${colours.black};
                width: 100%;
                padding-right: 24px;
                position: relative;
                cursor: pointer;

                &::after {
                    content: "";
                    border-bottom: 0;
                    ${mixins.triangle("5px", "10px", "down", colours.boulder)};
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transition: border ${transitions.transitionFaster};
                    transform: translateY(-50%);
                }
            }

            &__toggle-menu--open {
                &::after {
                    border-top: 0;
                    ${mixins.triangle("5px", "10px", "up", colours.boulder)};
                }
            }

            &__toggle-menu--up {
                &::after {
                    border-top: 0;
                    ${mixins.triangle("5px", "10px", "up", colours.boulder)};
                }
            }

            &__toggle-menu--up--open &__toggle-menu--up {
                &::after {
                    content: "";
                    border-bottom: 0;
                    ${mixins.triangle("5px", "10px", "down", colours.boulder)};
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transition: border ${transitions.transitionFaster};
                    transform: translateY(-50%);
                }
            }

            &__toggle-menu--show-placeholder {
                ${mixins.placeholderAttributes()};
            }

            &__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: ${colours.black};
                padding: 0 12px;
                cursor: pointer;
                height: 30px;
                line-height: 30px;
                width: 100%;
                font-weight: 300;
                transition: background-color ${transitions.transitionFaster};

                &:hover {
                    background-color: ${colours.pattensBlue3};
                }
            }

            &__item-icon {
                text-align: center;
                position: relative;
                width: 20px;
                height: 20px;
                line-height: 20px;
                margin-right: 10px;
                background-color: yellow;
            }

            &__item-text {
                white-space: nowrap;
                overflow: hidden;
            }

            &__item--inactive {
                background-color: ${colours.athensGray};
                cursor: unset;

                &:hover,
                &:focus {
                    background-color: ${colours.athensGray};
                    border-bottom: unset;
                }
            }

            &__item:focus,
            &__item--selected {
                outline: none;
                background-color: ${colours.pattensBlue2};
                border-bottom: 2px solid ${colours.spindle2};
            }
        }

        .dropdown--read-only {
            pointer-events: none;
        }

        .dropdown--scaled__search .dropdown--scaled {
            ${breakpoints.scalePropListUp([
                ["padding-right", "24px"],
                ["height", "28px"],
                ["line-height", "28px"],
            ])};
        }

        .dropdown--scaled__clear .dropdown--scaled {
            ${breakpoints.scalePropListUp([
                ["height", "32px"],
                ["padding-left", "5px"],
                ["padding-right", "5px"],
                ["right", "24px"],
            ])};

            svg {
                ${breakpoints.scalePropUp("height", "8px")};
            }
        }

        .dropdown--scaled__menu .dropdown--scaled {
            ${breakpoints.scalePropUp("max-height", "240px")};
        }

        .dropdown--scaled__toggle-menu .dropdown--scaled {
            ${breakpoints.scalePropListUp([
                ["font-size", "16px"],
                ["height", "32px"],
                ["line-height", "32px"],
                ["padding-right", "24px"],
            ])};

            &::after {
                ${breakpoints.scalePropUp("border-width", "5px")};
            }
        }

        .dropdown--scaled__item .dropdown--scaled {
            ${breakpoints.scalePropListUp([
                ["padding-left", "12px"],
                ["padding-right", "12px"],
                ["height", "30px"],
                ["line-height", "30px"],
                ["font-size", "16px"],
            ])};
        }

        .dropdown--scaled__item-icon .dropdown--scaled {
            ${breakpoints.scalePropListUp([
                ["width", "20px"],
                ["height", "20px"],
                ["line-height", "20px"],
                ["margin-right", "10px"],
            ])};
        }

        .one-line-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    `,
    modal: css`
        .ReactModal__Body--open {
            overflow: hidden;
            width: 100%;
            height: 100%;

            .visx-tooltip {
                z-index: ${zIndex.zIndexHighest + 1};
            }

            @media print {
                overflow: visible;
                position: unset;
            }
        }

        .ReactModal__Overlay {
            background: rgba(0, 0, 0, 0.5) !important;
            z-index: ${zIndex.zIndexHighest} !important;
        }

        .modal--scrollable {
            .modal-content {
                max-height: 100vh;
                overflow: scroll;
            }
        }

        .modal-dialog {
            .modal-header {
                border: none;
            }
        }

        .modal-header--absolute {
            position: absolute;
            top: 0;
            right: 0;

            .close {
                z-index: ${zIndex.zIndexHighest};
            }
        }

        .modal-content {
            overflow: hidden;
            border-radius: ${sizes.smallRadius};
        }

        .modal-footer--work-comparison {
            border: none;
            padding: 0 1rem 1rem 1rem;
            justify-content: space-between;
        }

        .modal-footer__button {
            font-size: 18px;
        }

        .modal-lg {
            ${breakpoints.down("md")} {
                max-width: 748px;
            }

            ${breakpoints.down("lg")} {
                max-width: 972px;
            }

            ${breakpoints.down("xl")} {
                max-width: 1180px;
            }
        }
    `,
};

export default graphs;
