const routes = {
    imports: {
        link: "/imports",
    },
    "imports/errors": {
        link: "/imports/:id/errors",
    },
    "imports/view": {
        link: "/imports/:id",
    },
};

export default routes;
