export * from "./metrics";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NOOP = (...args) => {};

export const getNumberWithCommas = (number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const BREAKPOINTS = Object.freeze({
    XS: 0,
    SM: 568,
    MD: 768,
    LG: 1024,
    XL: 1280,
    XXL: 1440,
    "3XL": 1600,
    "4XL": 1900,
    "5XL": 2200,
});

export const WRITING_STRENGTH_LEVELS = {
    low: {
        percentage: 20,
        label: "Low",
    },
    average: {
        percentage: 50,
        label: "Average",
    },
    strong: {
        percentage: 65,
        label: "Strong",
    },
    excellent: {
        percentage: 80,
        label: "Excellent",
    },
};
