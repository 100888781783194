// Colours - as per http://chir.ag/projects/name-that-color
// Useful alternative Colours https://www.color-blindness.com/color-name-hue

const red = {
    50: "#fff2f2",
    100: "#ffcdd2",
    200: "#ef9a9a",
    300: "#e57373",
    350: "#DC676C",
    400: "#ef5350",
    500: "#f44336",
    600: "#e53935",
    700: "#d32f2f",
    800: "#c62828",
    900: "#b71c1c",
    999: "#ff0000",
};

const green = {
    100: "#C8E6C9",
    200: "#A5D6A7",
    300: "#81C784",
    400: "#66BB6A",
    500: "#4CAF50",
    600: "#43A047",
    700: "#388E3C",
    800: "#2E7D32",
    900: "#1B5E20",
    950: "#145819",
};

const grey = {
    50: "#fafafa",
    80: "#F3F3F3",
    90: "#F4F4F4",
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    450: "#AAAAAA",
    500: "#9e9e9e",
    600: "#757575",
    650: "#707070",
    700: "#757575",
    720: "#555555",
    800: "#424242",
    900: "#212121",
    920: "#1B1D28",
};

const blue = {
    50: "#E3F2FD",
    100: "#AAD4EF",
    200: "#05AEF0",
    250: "#1D8BDB",
    300: "#0076FF",
    400: "#156CBC",
    450: "#0869B2",
    500: "#0964A7",
    600: "#364D9B",
    650: "#3B528F",
    700: "#0C4A93",
    800: "#222247",
    900: "#3C3E44",
};

const colours = {
    borderColor: grey[300],
    primary: green[900],
    secondary: red[500],
    black: "#040404",
    blue,
    cream: "#FFFFCE",
    green,
    grey,
    red,
    transparent: "rgba(0,0,0,0)", // Useful for transitions between no fill and fill.,
    white: "#FFFFFF",
    yellow: "#FCE359",

    alabaster: "#F8F8F8",
    alto2: "#DEDEDE",
    alto4: "#E0E0E0",
    anzac: "#D8B352",
    athensGray: "#e7eaf0",
    azureRadiance: "#0874FC",
    bahia: "#A4D90F",
    blueHaze: "#C2CBDB",
    blueHaze2: "#C2C3DC",
    blueHaze3: "#CBCDE3",
    blueHaze4: "#CBD3E1",
    bonJour: "#E0DCDC",
    boulder: "#757575",
    brilliantRose: "#F44EBB",
    brinkPink: "#FC747A",
    buccaneer: "#6f3842",
    carnation: "#F15154",
    carnationPink: "#FDA6BE",
    carrotOrange: "#F6931D",
    cerulean: "#05AEF0",
    chateauGreen: "#37B44E",
    christi: "#5C9512",
    cornflowerBlue: "#6F75FF",
    crimson: "#ED1C24",
    curiousBlue: "#1D80C3",
    curiousBlue2: "#27A9E0",
    danube: "#5b91cc",
    danube2: "#5fa1d6",
    denim: "#1B75BC",
    doveGray: "#707070",
    doveGray2: "#666666",
    dullLavender: "#938de8",
    elfGreen: "#088C7C",
    eucalyptus: "#2eb24a",
    fountainBlue: "#65bec7",
    frenchRose: "#F25579",
    gallery: "#eeeeee",
    gray3: "#AAAAAA",
    havelockBlue: "#649EDD",
    heather: "#C2CBD9",
    inchWorm: "#A3ED3C",
    jaffa: "#f2a039",
    lightGrey: "#EFEFEF",
    lightningYellow: "#FDBB13",
    limeade2: "#26A500",
    malibu: "#86DEF5",
    malibu2: "#5fa5ff",
    mantis: "#68C879",
    marigoldYellow: "#f9d676",
    mexicanRed: "#A22242",
    naturalGrey: "#888786",
    perano2: "#A0A7F5",
    pattensBlue2: "#D9E6FF",
    pattensBlue3: "#E8F1FF",
    plum3: "#90268E",
    poloBlue2: "#8DA8D6",
    regentStBlue: "#A3B9E1",
    rhino: "#343465",
    rockBlue: "#A2B0C9",
    roman: "#DC676C",
    tundora: "#4A4A4A",
    selectiveYellow: "#F4BB01",
    shark: "#1d2022",
    shipCove: "#7692C7",
    silverChalice: "#9E9E9E",
    soapStone: "#FFF9F7",
    spindle2: "#A6BEEA",
    steelBlue: "#4A81BE",
    steelBlue2: "#5275B8",
    sun: "#FCAF17",
    sundance: "#cbc761",
    sushi: "#8DC63F",
    vermilion: "#FF3F00",
    viola2: "#D090D0",
    violetEggplant: "#B505B5",
    waterloo2: "#707095",
    wildBlueYonder: "#8894bc",
    wildWaterMelon: "#ff487b",
    yellowGreen: "#CCE16F",
    pigeonPost: "#B4C2DE",
};

const graphPieColourList = [
    "#343465",
    "#ff7c43",
    "#26A500",
    "#B26FAC",
    "#1B75BC",
    "#B505B5",
    "#2eb24a",
    "#EA5B0C",
    "#A22242",
];

const graphBarColourList = [
    "#265AA6",
    "#FCAF17",
    "#C1354E",
    "#32AE70",
    "#36A9E1",
    "#EA5B0C",
    "#666666",
    "#892A81",
    "#F59C00",
    "#95C567",
    "#B26FAC",
    "#4A81BE",
    "#FF487B",
    "#CBC761",
];

const status = {
    success: colours.green[500],
    error: colours.red[500],
    info: colours.blue[500],
};

/**
 * Convert a hexadecimal integer to a decimal integer.
 *
 * @param {string} hex Hexadecimal integer.
 * @returns {number} Decimal integer.
 */
const hexToInt = (hex: string) => parseInt(parseInt(hex, 16).toString(10), 10);

const lightenHsl = (hsl: any, light: any) => {
    if (!hsl || hsl.length <= 0) {
        return "";
    }

    let newHsl = hsl.replace(/[hsl()]/g, "");
    newHsl = newHsl.split(", ");

    return `hsl(${newHsl[0]}, ${newHsl[1]}, ${light}%)`;
};

/**
 * Get an rgba colour from a given 6-digit hex colour and an alpha value (defaults to 1).
 *
 * @param {string} hex 6-digit hex rgb colour.
 * @param {number} alpha Alpha channel value.
 * @returns {string} rgba colour.
 */
const rgba = (hex: string, alpha = 1) => {
    if (!hex || hex.length !== 7) {
        throw Error(`Hex colour must have length of 7, got ${hex}`);
    }

    const rgb = [hex.substring(1, 3), hex.substring(3, 5), hex.substring(5, 7)];

    return `rgba(${hexToInt(rgb[0])}, ${hexToInt(rgb[1])}, ${hexToInt(
        rgb[2],
    )}, ${alpha})`;
};

const utils = {
    hexToInt,
    lightenHsl,
    rgba,
};

const writingStrength = {
    bad: colours.crimson,
    low: colours.brinkPink,
    average: colours.sun,
    strong: colours.inchWorm,
    excellent: colours.eucalyptus,
};

export const palette = {
    baseContainerBackground: rgba(colours.white, 0.6),
};

const v = {
    ...colours,
    graphPieColourList,
    graphBarColourList,
    status,
    utils,
    writingStrength,
    palette,
};

export default v;
